// Keenthemes' plugins
window.KTUtil = require('./components/util.js');
window.KTBlockUI = require('./components/blockui.js');
window.KTCookie = require('./components/cookie.js');
window.KTDialer = require('./components/dialer.js');
window.KTDrawer = require('./components/drawer.js');
window.KTEventHandler = require('./components/event-handler.js');
window.KTFeedback = require('./components/feedback.js');
window.KTImageInput = require('./components/image-input.js');
window.KTMenu = require('./components/menu.js');
window.KTPasswordMeter = require('./components/password-meter.js');
window.KTScroll = require('./components/scroll.js');
window.KTScrolltop = require('./components/scrolltop.js');
window.KTSearch = require('./components/search.js');
window.KTStepper = require('./components/stepper.js');
window.KTSticky = require('./components/sticky.js');
window.KTSwapper = require('./components/swapper.js');
window.KTToggle = require('./components/toggle.js');

// Layout base js
window.KTApp = require('./layout/app.js');
window.KTLayoutAside = require('./layout/aside.js');
window.KTLayoutExplore = require('./layout/explore.js');
window.KTLayoutSearch = require('./layout/search.js');
window.KTLayoutToolbar = require('./layout/toolbar.js');
